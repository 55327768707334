export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [~4],
		"/adatkezeles": [5],
		"/aruhaz": [~6,[2]],
		"/aruhaz/fizetes/[slug]": [~9,[2]],
		"/aruhaz/kosar": [10,[2]],
		"/aruhaz/rendeles": [~11,[2,3]],
		"/aruhaz/[category]": [~7,[2]],
		"/aruhaz/[category]/[product]": [~8,[2]],
		"/aszf": [12],
		"/biografia": [~13],
		"/csomagpont": [14],
		"/diszkografia": [~15],
		"/diszkografia/[slug]": [~16],
		"/fiok": [~17],
		"/fiok/adataim": [~18],
		"/fiok/elfelejtett-jelszo": [~19],
		"/fiok/elfelejtett-jelszo/[slug]": [~20],
		"/fiok/regisztracio": [~21],
		"/galeria": [~22],
		"/galeria/[slug]": [~23],
		"/hirek": [~24],
		"/hirek/preview/[...rest]": [~26],
		"/hirek/[slug]": [~25],
		"/impresszum": [27],
		"/koncertek": [~28],
		"/overloaded-server": [29]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';